import React from "react";
import { Link } from "react-router-dom";
import imgLogo from "../../../assets/hadoraba-logo.webp";
import "./footer.css";

const Footer = () => {
  return (
    <React.Fragment>
      <footer>
        <div className="container">
          <div className="sec aboutus">
            <div className="footer-title">
              <img src={imgLogo} alt="hadoraba-logo" />
            </div>
            <p>
              מרכז ידע והכשרות לילדים ונוער בנושאי תכנות וטכנולוגיה במרכזי הדור
              הבא אנו מלמדים את שפות התכנות והטכנולוגיות הפופולאריות בעולם
            </p>

            <ul className="sci">
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/hadoraba"
                >
                  <i className="fab fa-facebook"></i>
                </a>
              </li>

              {/* <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/hadoraba"
                >
                  <i className="fab fa-twitter"></i>
                </a>
              </li>*/}
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/hador.aba/"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.tiktok.com/@hadoraba?lang=en"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="#fff"
                      d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
                    />
                  </svg>
                </a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/hadoraba"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="#fff"
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UCDsp1g0XY_ahqB5SqOMSO5A?sub_confirmation=1"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="sec quickLinks">
            <h2>קישורים מהירים</h2>
            <ul>
              <li>
                <Link to="/about">אודות</Link>
              </li>
              <li>
                <Link to="/playground">מגרש המשחקים</Link>
              </li>
              <li>
                <Link to="/activities">פעילויות</Link>
              </li>
              <li>
                <Link to="/article/policy">תקנון האתר</Link>
              </li>
              <li>
                <Link to="/article/accessibility">הצהרת נגישות</Link>
              </li>
              <li>
                <Link to="/library">אזור למידה</Link>
              </li>
            </ul>
          </div>
          <div className="sec contact">
            <h2>פרטי קשר</h2>
            <ul className="info">
              <li>
                <span>
                  {" "}
                  <i className="fas fa-map-marker"></i>{" "}
                </span>
                <span>
                  תמר נוה אפרים 17
                  <br />
                  יהוד-מונוסון, מיקוד 6019000
                  <br />
                  ישראל
                </span>
              </li>

              <li>
                <span>
                  {" "}
                  <i className="fas fa-phone"></i>{" "}
                </span>
                <p>
                  <a href="tel:0554320014">055-4320014</a>
                </p>
              </li>
              <li>
                <span>
                  {" "}
                  <i className="fas fa-envelope"></i>
                </span>
                <p>
                  <a href="mailto:hadoraba@gmail.com">hadoraba@gmail.com</a>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="copyrightText">
          <p>&copy;כל הזכויות שמורות - הדור הבא 2017-2024</p>
        </div>
      </footer>
    </React.Fragment>
  );
};
export default Footer;
