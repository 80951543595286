import React from "react";

const ScratcherPart4 = () => {
  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h1>עדכונים למדריך לסקראצ'רים חלק עזר</h1>
      <h3>אין כרגע עדכונים</h3>
    </div>
  );
};
export default ScratcherPart4;
