import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import AboutMethod from "../UI/AboutMethod";
import CardsSection from "../UI/cardsSection";
import SwiperCardsVideos from "../UI/swiperCardsVideos";
import Contact from "../UI/contact";
import ImageBackground from "../UI/imageBackground";
import ScrollAnimation from "../UI/ScrollAnimation";

import {
  SEO,
  OnlineCoursesCardSectionData,
  Videos,
} from "../../dictionary/dictionary.json";
const { ActivitesOnlineT } = SEO;

import "./activities.css";

const ActivitesOnline = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const content = () => {
    return (
      <div>
        <p>
          רכישת מיומנויות תכנות בגיל צעיר תורמת לפיתוח החשיבה האנליטית, לשיפור
          אוצר המילים באנגלית ולטיפוח היזמות, הדמיון והיצירתיות.
          <br />
          עם קורסי הדור הבא בשיטת דל"ת להצלחה (דמיון, למידה, תכנות) תעניקו
          לילדים זמן איכות ללמידה מעשירה ומהנה וכישורי חיים להצלחה!
        </p>
        <div style={{ marginBottom: "20px" }}>
          <button
            className="btn btn-primary"
            onClick={() => scrollToSection("register")}
          >
            להרשמה לשני שיעורי ניסיון בחינם
          </button>
          <button
            className="btn btn-primary"
            onClick={() => scrollToSection("courses")}
          >
            לרשימת הקורסים
          </button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Helmet>
        <title>{ActivitesOnlineT.title}</title>
        <meta name="description" content={ActivitesOnlineT.desc} />
        <meta name="title" content={ActivitesOnlineT.title} />
        <meta name="keywords" content={ActivitesOnlineT.tags} />
      </Helmet>

      <div className="activitiesContainer">
        <ImageBackground
          title={"קורסי אונליין הדור הבא"}
          subtitle={"קורסי תכנות חווייתיים"}
          content={content}
          desktopSrc="/images/home/online_course_of_kids_learning_programming_lg.webp"
          mobileSrc="/images/home/online_course_of_kids_learning_programming.webp"
        />
        <div className="headerSubtitlePar">
          <p>
            רכישת מיומנויות תכנות בגיל צעיר תורמת לפיתוח החשיבה האנליטית, לשיפור
            אוצר המילים באנגלית ולטיפוח היזמות, הדמיון והיצירתיות.
            <br />
            עם קורסי הדור הבא בשיטת דל"ת להצלחה (דמיון, למידה, תכנות) תעניקו
            לילדים זמן איכות ללמידה מעשירה ומהנה וכישורי חיים להצלחה!
          </p>
          <div style={{ marginBottom: "20px" }}>
            <button
              className="btn btn-success"
              onClick={() => scrollToSection("register")}
            >
              להרשמה לשני שיעורי ניסיון בחינם
            </button>
            <button
              className="btn btn-success"
              onClick={() => scrollToSection("courses")}
            >
              לרשימת הקורסים
            </button>
          </div>
        </div>

        <AboutMethod />
        <ScrollAnimation animationType={"backInLeft"}>
          <div className="GoodToKnow">
            <h1>כדאי לדעת</h1>
            <p>
              ✔️מדריכים מנוסים בטכנולוגיות והדרכה, ומסורים לעזור ולנצל את מלוא
              הפוטנציאל של ילדכם
              <br />
              ✔️יחס אישי ועד 10 משתתפים בקבוצה בלבד
              <br />
              ✔️הקורסים שלנו מתאימים גם לילדים וילדות חסרי ניסיון בתכנות וגם
              לאלה שיש להם ניסיון והם רוצים להרחיב את הידע ואת כישורי התכנות
              שלהם
              <br />
              ✔️שירות ותמיכה בוואטסאפ או במייל 24/6 (מענה עד יום אחד לא כולל
              שבתות)
              <br />
              ✔️שיעורים מוקלטים הפתוחים לצפייה למשתתפים במהלך השנה
            </p>
          </div>
        </ScrollAnimation>
        <div style={{ color: "black" }}>
          <div id="courses">
            <CardsSection
              title={OnlineCoursesCardSectionData.title}
              cardsData={OnlineCoursesCardSectionData.cardsData}
            />
            <div className="comments-container">
              <h2>תלמידים ממליצים</h2>
              <SwiperCardsVideos data={Videos.data} />
            </div>
          </div>

          <div id="register">
            <Contact />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivitesOnline;
